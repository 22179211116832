import PropTypes from "prop-types";
import { Icon } from "react-bulma-components";
import { library, config } from "@fortawesome/fontawesome-svg-core";
import {
  faBorderAll,
  faList,
  faInfoCircle,
  faSearch,
  faTrophy,
  faFileUpload,
  faDollarSign,
  faGrinStars,
  faSun,
  faGlobeAfrica,
  faFunnelDollar,
  faUserClock,
  faUserFriends,
  faMagic,
  faHatWizard,
  faCrown,
  faMedal,
  faChalkboardTeacher,
  faFeather,
  faPaintBrush,
  faChevronCircleDown,
  faHandPointDown,
  faArrowRight,
  faChevronCircleRight,
  faArrowCircleRight,
  faCalculator,
  faMapMarkedAlt,
  faThumbsUp as solidThumbsUp,
  faThumbsDown as solidThumbsDown,
  faCalendarAlt,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import {
  faThumbsUp,
  faThumbsDown,
  faStar,
  faEnvelope
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLORS } from "../constants";

// Skip adding FA CSS since it's imported in styles.scss
config.autoAddCss = false;

library.add(
  solidThumbsUp,
  solidThumbsDown,
  faBorderAll,
  faList,
  faInfoCircle,
  faSearch,
  faTrophy,
  faFileUpload,
  faDollarSign,
  faGrinStars,
  faStar,
  faSun,
  faGlobeAfrica,
  faFunnelDollar,
  faUserClock,
  faUserFriends,
  faMagic,
  faHatWizard,
  faCrown,
  faMedal,
  faChalkboardTeacher,
  faFeather,
  faPaintBrush,
  faThumbsUp,
  faThumbsDown,
  faChevronCircleDown,
  faEnvelope,
  faHandPointDown,
  faArrowRight,
  faArrowCircleRight,
  faChevronCircleRight,
  faCalculator,
  faFacebookSquare,
  faFacebook,
  faLinkedin,
  faTwitterSquare,
  faSearch,
  faMapMarkedAlt,
  faCalendarAlt,
  faPlus
);

/**
 * @see https://bulma.io/documentation/elements/icon/#sizes
 * @param {String} size
 */
const getFASize = size => {
  switch (size) {
    case "small":
      return "";
    case "medium":
      return "2x";
    case "large":
      return "3x";
    default:
      return "lg";
  }
};

Icon.propTypes.icon = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string)
]);

const CustomIcon = ({ faSize = "1x", ...props }) => (
  <Icon {...props}>
    <FontAwesomeIcon {...props} size={faSize} />
  </Icon>
);

export default CustomIcon;
